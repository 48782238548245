<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'name'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields=selectFields
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')"></Column>
        </template>

        <template #form>
            <ContainerTypeForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import ContainerTypeForm from '@/components/containertype/ContainerTypeForm.vue';
import containerTypeService from '@/services/ContainerTypeService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        ContainerTypeForm
    },
    data() {
        return {
            item: {},
            itemService: containerTypeService,
            itemFormRef: null,
            selectFields: [
                "id",
                "name"
            ]
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
        
    }
}
</script>