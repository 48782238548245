import {ApiService} from '@/services/ApiService'

const URL = '/api/container-type'

class ContainerTypeService extends ApiService {
    constructor(){
        super()
    } 
    getUrl(){
        return URL
    }

}

export default new ContainerTypeService()